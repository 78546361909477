<template>
	<div class="data-board-main">
		<iframe :src="url" frameborder="0" style="width: 100%;height: 100%;"></iframe>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				url: 'http://120.24.225.235:9090/link/6rPZC2gQ'
			}
		},
		mounted() {
			let data = this.$route.query;
			this.url = data.url;
		},
		methods:{
			
		},
		watch: {
			$route(newValue, value) {
				// console.log('路由数据：', newValue);
				this.url = newValue.query.url;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.data-board-main{width: 100%;height: 100%;
		
	}
	
</style>